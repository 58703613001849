import React, { useCallback } from 'react';
import {
  useExposition,
  useExpositionActionsShare,
} from '@brainstud/academy-api/Hooks/useExpositions';
import { ShareableCreateInput } from '@brainstud/academy-api/Types/Resources/Shareable';
import { UUID } from '@brainstud/academy-api/Types/Utils/UUID';
import { Button } from '@brainstud/ui/Buttons/Button';
import { AutocompleteInput } from '@brainstud/ui/Form Input/AutocompleteInput';
import { SidebarModal } from '@brainstud/ui/Modals/SidebarModal';
import { TBody } from '@brainstud/ui/Static/Table/SubComponents/TBody';
import { Table } from '@brainstud/ui/Static/Table/Table';
import { Form } from '@brainstud/universal-components/Components/Form';
import classNames from 'classnames/bind';
import { Loading } from 'Components/Loading';
import { useShareablesWithTarget } from 'Hooks/ShareablesWithTarget/useShareablesWithTarget';
import { usePlausibleTracking } from 'Hooks/usePlausible';
import { useShareable } from 'Hooks/useShareable';
import { ShareableTableRow } from 'Modules/action-based-learning-panel/Views/Assignments/Portfolio/ShareableTableRow';
import { useToaster } from 'Providers/ToasterProvider/useToaster';
import { useTranslator } from 'Providers/Translator';
import styles from './ShareExpositionModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  expositionId: UUID;
  closeModal: () => void;
};

type CreateFormInput = {
  account: string;
  rights: string;
};

export const ShareExpositionModal = ({ expositionId, closeModal }: Props) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const create = useExpositionActionsShare({ exposition: expositionId });
  const plausible = usePlausibleTracking();

  const [{ data: exposition }, { isLoading: isLoadingExposition }] =
    useExposition({
      exposition: expositionId,
      include: ['shareables'],
    });

  const onSubmit = useCallback(
    (values: ShareableCreateInput) =>
      create.mutateAsync(values, {
        onSuccess: () => {
          setToast(
            t('views.portfolio.exposition.share.account.success'),
            'success'
          );
          plausible('expositions:modal:shared');
        },
        onError: () => {
          setToast(
            t('views.portfolio.exposition.share.account.failure'),
            'error',
            10000
          );
        },
      }),
    [create, plausible, setToast, t]
  );

  const shareables = useShareablesWithTarget('accounts', exposition);
  const { handleSubmit, handleSearch, handleChangeShare } = useShareable({
    onSubmit,
    shareables,
  });

  return (
    <SidebarModal
      onClickOutside
      onClose={closeModal}
      className={cx(styles.base)}
    >
      <SidebarModal.Header>
        <h2>
          {t('views.portfolio.exposition.share.confirm.title', {
            title: exposition?.title,
          })}
        </h2>
      </SidebarModal.Header>

      <Form<CreateFormInput> onSubmit={handleSubmit} language="nl">
        <div className={styles.buttons}>
          <AutocompleteInput
            label={t('views.portfolio.share_with_user')}
            name="account"
            data={handleSearch}
            onChange={handleChangeShare}
            placeholder={t('name')}
            className={cx(styles.autocomplete)}
            multiple
          >
            {({ onReset, values }) => (
              <Button
                outline
                loading={create.isPending}
                type="button"
                disabled={!Object.keys(values).length}
                onClick={async () => {
                  const result = await handleSubmit();
                  if (result) onReset();
                }}
              >
                {t('views.portfolio.share.action')}
              </Button>
            )}
          </AutocompleteInput>
        </div>
      </Form>

      {isLoadingExposition ? (
        <Loading />
      ) : (
        <>
          {shareables.length === 0 ? (
            <p>{t('views.portfolio.share.empty')}</p>
          ) : (
            <div className={cx(styles.vertical)}>
              <strong>{t('views.portfolio.list_of_users')}</strong>
              <Table className={cx(styles.table)}>
                <TBody>
                  {shareables.map((shareable) => (
                    <ShareableTableRow
                      key={shareable.id}
                      name={shareable.target?.().fullName}
                      access={shareable.access}
                      id={shareable.id}
                    />
                  ))}
                </TBody>
              </Table>
            </div>
          )}
        </>
      )}
    </SidebarModal>
  );
};
