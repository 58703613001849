import React, { useCallback } from 'react';
import { useExpositionActionsShare } from '@brainstud/academy-api/Hooks/useExpositions';
import { Exposition } from '@brainstud/academy-api/Types/Resources/Exposition';
import { ConfirmationModal } from '@brainstud/ui/Modals/ConfirmationModal';
import { Business, Delete, Edit, Share } from '@mui/icons-material';
import { ReactComponent as SharingExpositionSVG } from 'Assets/sharing-expositions.svg';
import classNames from 'classnames/bind';
import { useShareablesWithTarget } from 'Hooks/ShareablesWithTarget/useShareablesWithTarget';
import { useUserData } from 'Hooks/UserData';
import {
  DeleteExpositionModal,
  ExpositionModalForm,
} from 'Modules/blended-learning-catalog-panel/Modals';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useToaster } from 'Providers/ToasterProvider/useToaster';
import { useTranslator } from 'Providers/Translator';
import { ShareExpositionModal } from './Modals/ShareExpositionModal';
import { ExpositionActionUnshare } from './ExpositionActionUnshare';
import styles from './ExpositionActions.module.css';

const cx = classNames.bind(styles);

type TProps = {
  exposition: Exposition;
};

export const ExpositionActions = ({ exposition }: TProps) => {
  const [t] = useTranslator();
  const { openModal, closeModal } = useModals();
  const create = useExpositionActionsShare({ exposition: exposition.id });
  const [{ portal }] = useUserData();
  const [setToast] = useToaster();

  const [academyShareable] = useShareablesWithTarget('portals', exposition);

  const handleShareExposition = useCallback(
    () =>
      openModal(ConfirmationModal, {
        closeable: true,
        title: t('views.portfolio.exposition.share.confirm.title', {
          title: exposition.title,
        }),
        description: t('views.portfolio.exposition.share.confirm.question'),
        yes: t('confirm'),
        no: t('cancel'),
        children: (
          <div className={cx(styles['center-illustration'])}>
            <SharingExpositionSVG />
          </div>
        ),
        handleConfirm: () =>
          create.mutateAsync(
            {
              access: 'read',
              targets: [
                {
                  id: portal?.id || '',
                  type: 'portal',
                },
              ],
            },
            {
              onSuccess: () => {
                setToast(
                  t('views.portfolio.exposition.share.confirm.success'),
                  'success'
                );
                closeModal();
              },
              onError: () => {
                setToast(
                  t('views.portfolio.exposition.share.confirm.failure'),
                  'error'
                );
              },
            }
          ),
      }),
    [closeModal, create, exposition.title, portal, openModal, setToast, t]
  );

  return (
    <>
      <button
        role="menuitem"
        type="button"
        onClick={() => openModal(ExpositionModalForm, { exposition })}
      >
        <Edit />
        <span>{t('edit')}</span>
      </button>
      {!academyShareable ? (
        <button role="menuitem" type="button" onClick={handleShareExposition}>
          <Business />
          <span>{t('sharing.to_academy')}</span>
        </button>
      ) : (
        <ExpositionActionUnshare shareable={academyShareable} />
      )}
      <button
        role="menuitem"
        type="button"
        onClick={() =>
          openModal(ShareExpositionModal, { expositionId: exposition.id })
        }
      >
        <Share />
        <span>{t('share')}</span>
      </button>
      <button
        role="menuitem"
        type="button"
        onClick={() => openModal(DeleteExpositionModal, { exposition })}
      >
        <Delete />
        <span>{t('delete')}</span>
      </button>
    </>
  );
};
